import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import React, { useEffect } from "react"
import { ExtraImageProps } from "../../../../types/shared"

const alt = {
  visa: "Prepare Your Visa To Japan",
  health: "Prepare Your Health For Japan",
  howLong: "How Long To Stay In Japan",
  activities: "What Activities To Do In Japan",
  activities2: "What Activities To Do In Japan",
  accommodations: "What Are The Best Accommodations In Japan",
  baggage: "What To Bring In Japan",
  transports: "Tuk Tuk",
  opinion: "Opinion Japan",
  opinion2: "Opinion Japan",
  opinion3: "Opinion Japan",
  currency: "Application Currency",
  cardFr1: "Prepare Travel To Japan Pinterest card",
  cardFr2: "Prepare Travel To Japan Pinterest card",
  cardEn1: "Prepare Travel To Japan Pinterest card",
  cardEn2: "Prepare Travel To Japan Pinterest card",
}

export const PrepareJapanImages: React.FunctionComponent<ExtraImageProps & { image: keyof typeof alt }> = ({
  className = "",
  image,
  fluidObject = {},
  imgStyle = {},
  onLoad,
}) => {
  const data = useStaticQuery(graphql`
    query {
      visa: file(relativePath: { eq: "asia/japan/prepare-japan/prepare-japan-visa.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 70, srcSetBreakpoints: [600]) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      howLong: file(relativePath: { eq: "asia/japan/prepare-japan/prepare-japan-how-long.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 70, srcSetBreakpoints: [600]) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      activities: file(relativePath: { eq: "asia/japan/prepare-japan/prepare-japan-activities.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 70, srcSetBreakpoints: [600]) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      activities2: file(relativePath: { eq: "asia/japan/prepare-japan/prepare-japan-activities2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 70, srcSetBreakpoints: [600]) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      transports: file(relativePath: { eq: "asia/japan/prepare-japan/prepare-japan-transports.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 60, srcSetBreakpoints: [600]) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      accommodations: file(relativePath: { eq: "asia/japan/prepare-japan/prepare-japan-accommodations.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 60, srcSetBreakpoints: [600]) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      baggage: file(relativePath: { eq: "asia/japan/prepare-japan/prepare-japan-baggage.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 60, srcSetBreakpoints: [600]) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      health: file(relativePath: { eq: "asia/japan/prepare-japan/prepare-japan-health.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 60, srcSetBreakpoints: [600]) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      opinion: file(relativePath: { eq: "asia/japan/prepare-japan/prepare-japan-opinion-landscape.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 80, srcSetBreakpoints: [600]) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      opinion2: file(relativePath: { eq: "asia/japan/prepare-japan/prepare-japan-opinion-tokyo.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 60, srcSetBreakpoints: [600]) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      opinion3: file(relativePath: { eq: "asia/japan/prepare-japan/prepare-japan-opinion-language.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 60, srcSetBreakpoints: [600]) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      currency: file(relativePath: { eq: "asia/japan/prepare-japan/prepare-japan-currency.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 60, srcSetBreakpoints: [600]) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      cardFr1: file(relativePath: { eq: "asia/japan/prepare-japan/card-fr1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 60, srcSetBreakpoints: [1000]) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      cardFr2: file(relativePath: { eq: "asia/japan/prepare-japan/card-fr2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 60, srcSetBreakpoints: [1000]) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      cardEn1: file(relativePath: { eq: "asia/japan/prepare-japan/card-en1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 60, srcSetBreakpoints: [1000]) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      cardEn2: file(relativePath: { eq: "asia/japan/prepare-japan/card-en2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 60, srcSetBreakpoints: [1000]) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  useEffect(() => {
    if (onLoad) onLoad(data[image].childImageSharp.fluid.src)
  }, [data, image, onLoad])

  return (
    <Img
      fluid={{ ...data[image].childImageSharp.fluid, ...fluidObject }}
      alt={alt[image]}
      className={className}
      imgStyle={imgStyle}
    />
  )
}
