import React from "react"

export const JapanPrepareFlag = () => (
  <svg height="512pt" viewBox="0 0 512 512" width="512pt" xmlns="http://www.w3.org/2000/svg">
    <path
      d="m512 15v270c0 6.898438-4.800781 12.898438-11.398438 14.398438l-61 15c-41.703124 10.5-85.5 10.5-127.203124 0-8.699219-2.097657-17.398438-3.597657-26.398438-4.796876-28.5-3.601562-58.199219-2.101562-86.398438 4.796876l-60 15c-1.199218.601562-2.402343.601562-3.601562.601562h-106v-302.402344c53.101562 5.101563 108.101562 1.5 160.601562-11.699218 32.097657-7.800782 64.199219-9.898438 95.398438-6.296876 11.398438 1.199219 22.5 3.296876 33.601562 5.996094 36.898438 9.003906 75.898438 9.003906 112.796876 0l61-15c4.503906-1.199218 9.300781-.296875 12.902343 2.703125 3.597657 2.699219 5.699219 7.199219 5.699219 11.699219zm0 0"
      fill="#f2f2fc"
    />
    <path
      d="m512 15v270c0 6.898438-4.800781 12.898438-11.398438 14.398438l-61 15c-41.703124 10.5-85.5 10.5-127.203124 0-8.699219-2.097657-17.398438-3.597657-26.398438-4.796876v-300c11.398438 1.199219 22.5 3.296876 33.601562 5.996094 36.898438 9.003906 75.898438 9.003906 112.796876 0l61-15c4.503906-1.199218 9.300781-.296875 12.902343 2.703125 3.597657 2.699219 5.699219 7.199219 5.699219 11.699219zm0 0"
      fill="#d8d8fc"
    />
    <path
      d="m286 90c-41.398438 0-75 33.601562-75 75s33.601562 75 75 75 75-33.601562 75-75-33.601562-75-75-75zm0 0"
      fill="#ff637b"
    />
    <path
      d="m45 0h-30c-8.402344 0-15 6.597656-15 15v482c0 8.398438 6.597656 15 15 15h30c8.402344 0 15-6.601562 15-15v-482c0-8.402344-6.597656-15-15-15zm0 0"
      fill="#7c8388"
    />
    <path d="m60 15v482c0 8.398438-6.597656 15-15 15h-15v-512h15c8.402344 0 15 6.597656 15 15zm0 0" fill="#575f64" />
    <path d="m361 165c0 41.398438-33.601562 75-75 75v-150c41.398438 0 75 33.601562 75 75zm0 0" fill="#e63950" />
  </svg>
)

export const JapanFan = () => (
  <svg height="463pt" viewBox="0 -35 463.9155 463" width="463pt" xmlns="http://www.w3.org/2000/svg">
    <path
      d="m263.824219 376.457031c-2.429688 0-4.726563-1.101562-6.246094-3l-7.015625-8.800781c-17.273438-21.644531-26.695312-48.507812-26.738281-76.199219 0-3.234375 1.945312-6.15625 4.9375-7.394531 2.988281-1.242188 6.429687-.558594 8.71875 1.730469l6.167969 6.160156c15.601562 15.65625 35.730468 26.011719 57.535156 29.601563l3.960937.65625c4.359375.726562 7.300781 4.851562 6.574219 9.210937-.730469 4.359375-4.855469 7.300781-9.214844 6.570313l-3.949218-.664063c-20.8125-3.421875-40.371094-12.222656-56.738282-25.527344 3.28125 16.78125 10.554688 32.523438 21.210938 45.898438l7.007812 8.757812c1.917969 2.398438 2.296875 5.679688.972656 8.449219-1.324218 2.769531-4.113281 4.539062-7.183593 4.550781zm0 0"
      fill="#daa857"
    />
    <path
      d="m287.824219 392.457031c-.535157-.015625-1.070313-.070312-1.597657-.160156-.503906-.085937-.988281-.246094-1.441406-.480469-.511718-.175781-1-.417968-1.449218-.71875l-1.191407-.960937c-1.464843-1.53125-2.292969-3.558594-2.320312-5.679688.015625-1.042969.230469-2.078125.640625-3.039062.40625-.96875.972656-1.863281 1.679687-2.640625l1.191407-.960938c.449218-.300781.9375-.542968 1.449218-.71875.453125-.230468.9375-.394531 1.441406-.480468 1.558594-.328126 3.179688-.160157 4.640626.480468.964843.40625 1.859374.976563 2.636718 1.679688.707032.777344 1.277344 1.671875 1.679688 2.640625.410156.960937.628906 1.996093.640625 3.039062-.03125 2.117188-.859375 4.148438-2.320313 5.679688-.777344.707031-1.671875 1.273437-2.636718 1.679687-.949219.449219-1.992188.667969-3.042969.640625zm0 0"
      fill="#b94137"
    />
    <path
      d="m335.824219 336.457031c-.535157-.015625-1.070313-.070312-1.597657-.160156-.503906-.085937-.988281-.246094-1.441406-.480469-.511718-.175781-1-.417968-1.449218-.71875l-1.191407-.960937c-.722656-.765625-1.292969-1.660157-1.679687-2.640625-.449219-.949219-.667969-1.988282-.640625-3.039063.027343-2.117187.855469-4.148437 2.320312-5.679687l1.191407-.960938c.449218-.300781.9375-.542968 1.449218-.71875.453125-.230468.9375-.394531 1.441406-.480468 1.023438-.238282 2.085938-.238282 3.109376 0 .535156.074218 1.050781.238281 1.53125.480468.488281.160156.949218.40625 1.359374.71875.472657.320313.878907.640625 1.277344.960938 1.460938 1.535156 2.289063 3.5625 2.320313 5.679687-.011719 1.046875-.230469 2.078125-.640625 3.039063-.382813.980468-.953125 1.875-1.679688 2.640625-.398437.320312-.796875.640625-1.277344.960937-.410156.316406-.871093.558594-1.359374.71875-.480469.246094-.996094.40625-1.53125.480469-.496094.09375-1.003907.148437-1.511719.160156zm0 0"
      fill="#b94137"
    />
    <path
      d="m231.824219 296.457031c-2.714844 0-5.246094-1.375-6.71875-3.65625l-48.070313-74.230469c-2.402344-3.710937-1.34375-8.664062 2.367188-11.066406 3.707031-2.398437 8.660156-1.339844 11.0625 2.371094l41.359375 63.863281 41.359375-63.863281c2.402344-3.710938 7.355468-4.769531 11.066406-2.371094 3.707031 2.402344 4.769531 7.355469 2.367188 11.066406l-48.070313 74.230469c-1.476563 2.28125-4.007813 3.65625-6.722656 3.65625zm0 0"
      fill="#b94137"
    />
    <path
      d="m231.824219 296.457031c-4.417969 0-8-3.582031-8-8v-96c0-4.417969 3.582031-8 8-8s8 3.582031 8 8v96c0 4.417969-3.582031 8-8 8zm0 0"
      fill="#b94137"
    />
    <path
      d="m425.34375 112.539062c-43.300781-65.578124-116.867188-104.761718-195.449219-104.101562-78.582031.664062-151.480469 41.082031-193.667969 107.378906-12.785156 19.886719-22.390624 41.640625-28.480468 64.480469l129.199218 62.320313c1.472657-10.3125 4.636719-20.3125 9.359376-29.597657 5.015624-9.832031 11.667968-18.734375 19.679687-26.320312 8.128906-7.761719 17.585937-13.992188 27.921875-18.402344v-.078125c24.21875-10.347656 51.617188-10.347656 75.839844 0v.078125c10.335937 4.410156 19.792968 10.640625 27.917968 18.402344 7.582032 7.179687 13.933594 15.558593 18.800782 24.796875 5.214844 9.695312 8.679687 20.226562 10.242187 31.121094l129.199219-62.320313c-6.441406-24.09375-16.765625-46.980469-30.5625-67.757813zm-231.4375 55.757813v-.078125c24.21875-10.347656 51.617188-10.347656 75.839844 0v.078125"
      fill="#c7463b"
    />
    <g fill="#b94137">
      <path d="m231.824219 296.457031c-1.203125 0-2.394531-.273437-3.480469-.800781l-224.070312-108.152344c-3.8125-2.003906-5.351563-6.675781-3.476563-10.554687 1.871094-3.882813 6.484375-5.582031 10.429687-3.84375l220.597657 106.472656 220.601562-106.488281c3.945313-1.738282 8.554688-.039063 10.429688 3.84375 1.875 3.878906.335937 8.546875-3.476563 10.554687l-224.074218 108.167969c-1.082032.527344-2.273438.800781-3.480469.800781zm0 0" />
      <path d="m297.65625 194.699219c-1.535156 0-3.039062-.449219-4.328125-1.289063-1.785156-1.148437-3.042969-2.960937-3.492187-5.035156-.449219-2.074219-.058594-4.246094 1.09375-6.027344l81.273437-125.601562c2.398437-3.710938 7.351563-4.769532 11.0625-2.367188 3.707031 2.398438 4.769531 7.355469 2.367187 11.0625l-81.246093 125.601563c-1.476563 2.28125-4.011719 3.660156-6.730469 3.65625zm0 0" />
      <path d="m165.992188 194.699219c-2.71875.003906-5.25-1.375-6.726563-3.65625l-81.273437-125.601563c-2.398438-3.707031-1.339844-8.664062 2.371093-11.0625 3.707031-2.402344 8.660157-1.34375 11.0625 2.367188l81.273438 125.601562c1.148437 1.78125 1.542969 3.953125 1.09375 6.027344-.453125 2.074219-1.707031 3.886719-3.496094 5.035156-1.277344.835938-2.773437 1.285156-4.304687 1.289063zm0 0" />
      <path d="m231.824219 168.457031c-4.417969 0-8-3.582031-8-8v-48c0-4.417969 3.582031-8 8-8s8 3.582031 8 8v48c0 4.417969-3.582031 8-8 8zm0 0" />
      <path d="m231.824219 56.457031c-4.417969 0-8-3.582031-8-8v-40c0-4.417969 3.582031-8 8-8s8 3.582031 8 8v40c0 4.417969-3.582031 8-8 8zm0 0" />
      <path d="m316.464844 219.496094c-3.304688.003906-6.269532-2.027344-7.464844-5.113282-1.191406-3.082031-.363281-6.582031 2.082031-8.804687l108.886719-98.960937c2.109375-1.960938 5.109375-2.640626 7.859375-1.773438 2.746094.867188 4.816406 3.144531 5.417969 5.960938.597656 2.816406-.363282 5.742187-2.515625 7.652343l-108.882813 98.960938c-1.472656 1.339843-3.390625 2.082031-5.382812 2.078125zm0 0" />
      <path d="m146.304688 221.019531c-1.953126 0-3.839844-.714843-5.304688-2.011719l-110.070312-97.199218c-3.3125-2.921875-3.628907-7.980469-.703126-11.292969 2.921876-3.316406 7.980469-3.628906 11.292969-.707031l110.082031 97.199218c2.496094 2.203126 3.367188 5.71875 2.191407 8.832032-1.179688 3.113281-4.160157 5.171875-7.488281 5.167968zm0 0" />
      <path d="m269.746094 176.257812c-4.417969 0-8-3.582031-8-8v-.078124c0-.816407.128906-1.625.375-2.402344l45.601562-144.238282c.792969-2.804687 3.046875-4.953124 5.882813-5.613281 2.839843-.660156 5.8125.273438 7.761719 2.441407 1.949218 2.164062 2.566406 5.21875 1.609374 7.96875l-45.550781 144.089843c-.96875 3.441407-4.105469 5.824219-7.679687 5.832031zm0 0" />
      <path d="m193.90625 176.257812c-3.714844.007813-6.941406-2.539062-7.800781-6.152343l-45.433594-143.71875c-.953125-2.753907-.335937-5.808594 1.613281-7.972657 1.949219-2.167968 4.921875-3.101562 7.757813-2.441406 2.839843.660156 5.09375 2.808594 5.886719 5.613282l45.601562 144.238281c.246094.777343.371094 1.585937.375 2.402343.007812 2.125-.832031 4.167969-2.332031 5.675782-1.5 1.507812-3.542969 2.355468-5.667969 2.355468zm0 0" />
    </g>
    <path
      d="m263.824219 80.457031c0 17.675781-14.324219 32-32 32-17.671875 0-32-14.324219-32-32 0-17.671875 14.328125-32 32-32 17.675781 0 32 14.328125 32 32zm0 0"
      fill="#eaeae8"
    />
    <path
      d="m138.480469 234.515625-127.253907-61.425781c-3.945312-1.738282-8.558593-.039063-10.429687 3.84375-1.875 3.878906-.335937 8.546875 3.476563 10.554687l132 63.707031.695312-8.59375c.367188-2.726562.890625-5.433593 1.511719-8.085937zm0 0"
      fill="#b94137"
    />
    <path
      d="m295.824219 256.457031c-.015625-2.519531-.179688-5.035156-.496094-7.535156l-63.503906 30.65625-63.503907-30.65625c-.3125 2.5-.480468 5.015625-.496093 7.535156 0 .402344.082031.722657.082031 1.121094v8.894531l60.4375 29.183594c2.199219 1.0625 4.761719 1.0625 6.960938 0l60.472656-29.183594v-8.894531c-.03125-.398437.046875-.71875.046875-1.121094zm0 0"
      fill="#b94137"
    />
    <path
      d="m463.105469 176.816406c-1.921875-3.976562-6.703125-5.644531-10.679688-3.726562l-127.257812 61.425781c.625 2.664063 1.144531 5.359375 1.539062 8.101563l.328125 8.800781 132.34375-63.878907c1.917969-.921874 3.394532-2.574218 4.09375-4.585937.699219-2.011719.566406-4.21875-.367187-6.136719zm0 0"
      fill="#b94137"
    />
    <path
      d="m326.707031 242.617188c-.394531-2.742188-.914062-5.4375-1.539062-8.101563-1.878907-8.023437-4.804688-15.761719-8.703125-23.019531-4.867188-9.238282-11.21875-17.617188-18.800782-24.796875-8.125-7.761719-17.582031-13.992188-27.917968-18.402344v-.078125c-24.222656-10.347656-51.621094-10.347656-75.839844 0v.078125c-10.335938 4.410156-19.792969 10.640625-27.921875 18.402344-8.011719 7.585937-14.664063 16.488281-19.679687 26.320312-3.464844 6.820313-6.09375 14.039063-7.824219 21.496094-.621094 2.652344-1.144531 5.359375-1.535157 8.101563l-.695312 8.59375 31.625 15.261718v-8.894531c0-.398437-.082031-.71875-.082031-1.121094.015625-2.519531.179687-5.035156.496093-7.535156 1.527344-12.863281 6.914063-24.964844 15.457032-34.703125 17.65625-19.824219 45.699218-26.726562 70.542968-17.363281 24.839844 9.363281 41.351563 33.054687 41.535157 59.601562 0 .402344-.078125.722657-.078125 1.121094v8.894531l31.28125-15.085937zm0 0"
      fill="#eaeae8"
    />
  </svg>
)

export const JapanCalendar = () => (
  <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 512 512">
    <rect x="32" y="64" fill="#EDEDED" width="396.8" height="396.8" />
    <g>
      <rect x="102.4" y="192" fill="#D8D8DA" width="51.2" height="38.4" />
      <rect x="204.8" y="192" fill="#D8D8DA" width="51.2" height="38.4" />
      <rect x="307.2" y="192" fill="#D8D8DA" width="51.2" height="38.4" />
      <rect x="102.4" y="281.6" fill="#D8D8DA" width="51.2" height="38.4" />
      <rect x="204.8" y="281.6" fill="#D8D8DA" width="51.2" height="38.4" />
      <rect x="307.2" y="281.6" fill="#D8D8DA" width="51.2" height="38.4" />
      <rect x="102.4" y="371.2" fill="#D8D8DA" width="51.2" height="38.4" />
      <rect x="204.8" y="371.2" fill="#D8D8DA" width="51.2" height="38.4" />
    </g>
    <path
      fill="#EDEDED"
      d="M147.2,76.8h-38.4V19.2C108.8,8.596,117.396,0,128,0l0,0c10.604,0,19.2,8.596,19.2,19.2V76.8z"
    />
    <g>
      <path
        fill="#D8D8DA"
        d="M352,76.8h-38.4V19.2c0-10.604,8.596-19.2,19.2-19.2l0,0C343.404,0,352,8.596,352,19.2V76.8z"
      />
      <path
        fill="#D8D8DA"
        d="M428.8,460.8V64h-51.2v217.6c-70.692,0-128,57.307-128,128c0,18.205,3.809,35.52,10.661,51.2H428.8z
"
      />
    </g>
    <circle fill="#F8F8F9" cx="377.6" cy="409.6" r="102.4" />
    <path
      fill="#EDEDED"
      d="M377.6,307.2c-8.84,0-17.418,1.12-25.6,3.227c44.166,11.368,76.8,51.46,76.8,99.173
c0,47.715-32.634,87.807-76.8,99.173c8.182,2.106,16.76,3.227,25.6,3.227c56.554,0,102.4-45.846,102.4-102.4
S434.154,307.2,377.6,307.2z"
    />
    <rect x="32" y="64" fill="#E07188" width="396.8" height="76.8" />
    <rect x="377.6" y="64" fill="#DC4161" width="51.2" height="76.8" />
    <path
      fill="#88888F"
      d="M352,444.8c-2.456,0-4.914-0.937-6.788-2.812c-3.749-3.749-3.749-9.828,0-13.576L368,405.624V358.4
c0-5.302,4.298-9.6,9.6-9.6s9.6,4.298,9.6,9.6v51.2c0,2.546-1.011,4.988-2.812,6.788l-25.6,25.6
C356.914,443.863,354.456,444.8,352,444.8z"
    />
  </svg>
)

export const Fuji = () => (
  <svg height="480pt" viewBox="0 -4 480 480" width="480pt" xmlns="http://www.w3.org/2000/svg">
    <g fill="#b94137">
      <path d="m240 440c-4.417969 0-8-3.582031-8-8v-40c0-4.417969 3.582031-8 8-8s8 3.582031 8 8v40c0 4.417969-3.582031 8-8 8zm0 0" />
      <path d="m288 440c-4.417969 0-8-3.582031-8-8v-40c0-4.417969 3.582031-8 8-8s8 3.582031 8 8v40c0 4.417969-3.582031 8-8 8zm0 0" />
      <path d="m336 448c-4.417969 0-8-3.582031-8-8v-48c0-4.417969 3.582031-8 8-8s8 3.582031 8 8v48c0 4.417969-3.582031 8-8 8zm0 0" />
      <path d="m384 456c-4.417969 0-8-3.582031-8-8v-56c0-4.417969 3.582031-8 8-8s8 3.582031 8 8v56c0 4.417969-3.582031 8-8 8zm0 0" />
      <path d="m432 472c-4.417969 0-8-3.582031-8-8v-72c0-4.417969 3.582031-8 8-8s8 3.582031 8 8v72c0 4.417969-3.582031 8-8 8zm0 0" />
      <path d="m96 456c-4.417969 0-8-3.582031-8-8v-56c0-4.417969 3.582031-8 8-8s8 3.582031 8 8v56c0 4.417969-3.582031 8-8 8zm0 0" />
      <path d="m48 472c-4.417969 0-8-3.582031-8-8v-72c0-4.417969 3.582031-8 8-8s8 3.582031 8 8v72c0 4.417969-3.582031 8-8 8zm0 0" />
      <path d="m144 448c-4.417969 0-8-3.582031-8-8v-48c0-4.417969 3.582031-8 8-8s8 3.582031 8 8v48c0 4.417969-3.582031 8-8 8zm0 0" />
      <path d="m192 440c-4.417969 0-8-3.582031-8-8v-40c0-4.417969 3.582031-8 8-8s8 3.582031 8 8v40c0 4.417969-3.582031 8-8 8zm0 0" />
    </g>
    <path
      d="m380 140c0 77.320312-62.679688 140-140 140s-140-62.679688-140-140 62.679688-140 140-140 140 62.679688 140 140zm0 0"
      fill="#f55648"
    />
    <path d="m472 392h-464l144-264h176zm0 0" fill="#e4e4e2" />
    <path d="m264 392h-192v-32c0-17.671875 14.328125-32 32-32h160zm0 0" fill="#f9f9f9" />
    <path d="m72 352h40v16h-40zm0 0" fill="#64a8b2" />
    <path d="m264 328h208v64h-208zm0 0" fill="#f9f9f9" />
    <path
      d="m472 472h-40c-.757812-.003906-1.511719-.109375-2.238281-.320312l-21.761719-6.34375c-109.707031-32.054688-226.292969-32.054688-336 0l-21.761719 6.34375c-.726562.210937-1.480469.316406-2.238281.320312h-40c-4.417969 0-8-3.582031-8-8v-72c0-4.417969 3.582031-8 8-8h464c4.417969 0 8 3.582031 8 8v72c0 4.417969-3.582031 8-8 8zm-38.855469-16h30.855469v-56h-448v56h30.855469l20.664062-6.023438c112.632813-32.921874 232.328125-32.921874 344.960938 0zm0 0"
      fill="#c7463b"
    />
    <path d="m128 352h16v16h-16zm0 0" fill="#64a8b2" />
    <path d="m160 352h16v16h-16zm0 0" fill="#64a8b2" />
    <path d="m192 352h16v16h-16zm0 0" fill="#64a8b2" />
    <path d="m224 352h16v16h-16zm0 0" fill="#64a8b2" />
    <path d="m288 352h16v16h-16zm0 0" fill="#64a8b2" />
    <path d="m320 352h16v16h-16zm0 0" fill="#64a8b2" />
    <path d="m352 352h16v16h-16zm0 0" fill="#64a8b2" />
    <path d="m384 352h88v16h-88zm0 0" fill="#64a8b2" />
    <path
      d="m472 104h-64c-4.417969 0-8-3.582031-8-8s3.582031-8 8-8h64c4.417969 0 8 3.582031 8 8s-3.582031 8-8 8zm0 0"
      fill="#aedee2"
    />
    <path
      d="m472 168h-64c-4.417969 0-8-3.582031-8-8s3.582031-8 8-8h64c4.417969 0 8 3.582031 8 8s-3.582031 8-8 8zm0 0"
      fill="#aedee2"
    />
    <path
      d="m424 136h-56c-4.417969 0-8-3.582031-8-8s3.582031-8 8-8h56c4.417969 0 8 3.582031 8 8s-3.582031 8-8 8zm0 0"
      fill="#aedee2"
    />
    <path
      d="m472 136h-16c-4.417969 0-8-3.582031-8-8s3.582031-8 8-8h16c4.417969 0 8 3.582031 8 8s-3.582031 8-8 8zm0 0"
      fill="#aedee2"
    />
    <path
      d="m88 16h-48c-4.417969 0-8-3.582031-8-8s3.582031-8 8-8h48c4.417969 0 8 3.582031 8 8s-3.582031 8-8 8zm0 0"
      fill="#aedee2"
    />
    <path
      d="m144 80h-120c-4.417969 0-8-3.582031-8-8s3.582031-8 8-8h120c4.417969 0 8 3.582031 8 8s-3.582031 8-8 8zm0 0"
      fill="#aedee2"
    />
    <path
      d="m176 48h-104c-4.417969 0-8-3.582031-8-8s3.582031-8 8-8h104c4.417969 0 8 3.582031 8 8s-3.582031 8-8 8zm0 0"
      fill="#aedee2"
    />
    <path
      d="m40 48h-32c-4.417969 0-8-3.582031-8-8s3.582031-8 8-8h32c4.417969 0 8 3.582031 8 8s-3.582031 8-8 8zm0 0"
      fill="#aedee2"
    />
    <path
      d="m136 16h-16c-4.417969 0-8-3.582031-8-8s3.582031-8 8-8h16c4.417969 0 8 3.582031 8 8s-3.582031 8-8 8zm0 0"
      fill="#aedee2"
    />
    <path d="m256 328h16v56h-16zm0 0" fill="#ebebeb" />
    <path
      d="m128 208 32 32 32-32 32 32 32-32 32 32 32-32 32 32 24-24-48-88h-176l-53.191406 97.511719zm0 0"
      fill="#f9f9f9"
    />
  </svg>
)

export const Tori = () => (
  <svg height="464pt" viewBox="0 -48 464 464" width="464pt" xmlns="http://www.w3.org/2000/svg">
    <g fill="#dc4d41">
      <path d="m168 368h-112l24-304h64zm0 0" />
      <path d="m408 368h-112l24-304h64zm0 0" />
      <path d="m168 368h-112l24-304h64zm0 0" />
      <path d="m408 368h-112l24-304h64zm0 0" />
    </g>
    <path d="m56 336h112v32h-112zm0 0" fill="#787d82" />
    <path d="m296 336h112v32h-112zm0 0" fill="#787d82" />
    <path d="m208 56h48v48h-48zm0 0" fill="#62666a" />
    <path
      d="m435.152344 32h-406.304688c-8.035156.011719-15.542968-4-20-10.6875l-8.847656-21.3125h464l-8.871094 21.3125c-4.453125 6.679688-11.949218 10.691406-19.976562 10.6875zm0 0"
      fill="#c7463b"
    />
    <path d="m416 64h-368c-8.835938 0-16-7.164062-16-16v-16h400v16c0 8.835938-7.164062 16-16 16zm0 0" fill="#b94137" />
    <path d="m40 96h384v32h-384zm0 0" fill="#e85244" />
    <path d="m96 304h32v64h-32zm0 0" fill="#a8a8a8" />
    <path d="m96 336h32v32h-32zm0 0" fill="#b9b9b9" />
    <path d="m336 304h32v64h-32zm0 0" fill="#a8a8a8" />
    <path d="m336 336h32v32h-32zm0 0" fill="#b9b9b9" />
    <path d="m88 272h48v32h-48zm0 0" fill="#cdcdcd" />
    <path d="m152 272h-80l16-32h48zm0 0" fill="#9c9c9c" />
    <path d="m328 272h48v32h-48zm0 0" fill="#cdcdcd" />
    <path d="m392 272h-80l16-32h48zm0 0" fill="#9c9c9c" />
    <path d="m96 304h32v8h-32zm0 0" fill="#787d82" />
    <path d="m336 304h32v8h-32zm0 0" fill="#787d82" />
    <path d="m73.6875 144h76.625l-1.257812-16h-74.109376zm0 0" fill="#c7463b" />
    <path d="m313.6875 144h76.625l-1.257812-16h-74.109376zm0 0" fill="#c7463b" />
    <path d="m145.265625 80-1.265625-16h-64l-1.265625 16zm0 0" fill="#c7463b" />
    <path d="m385.265625 80-1.265625-16h-64l-1.265625 16zm0 0" fill="#c7463b" />
    <path d="m32 32h400v16h-400zm0 0" fill="#a83b32" />
  </svg>
)

export const Shinkansen = () => (
  <svg width="64" height="64" viewBox="0 -96 472 472" xmlns="http://www.w3.org/2000/svg">
    <path
      d="m464 184h-464v-8.296875c0-14.15625 9.058594-26.722656 22.488281-31.199219 26.875-5.503906 51.078125-19.980468 68.640625-41.054687 8.152344-9.789063 20.234375-15.449219 32.976563-15.449219h339.894531zm0 0"
      fill="#e4e4e2"
    />
    <path
      d="m74.191406 120c-15.023437 12.191406-32.753906 20.59375-51.703125 24.503906-4.609375 1.554688-8.816406 4.117188-12.3125 7.496094h64.566407c28.976562 0 56.769531-11.511719 77.257812-32zm0 0"
      fill="#b8eaee"
    />
    <path
      d="m22.488281 144.503906c-4.609375 1.554688-8.816406 4.117188-12.3125 7.496094h40.566407c28.976562 0 56.769531-11.511719 77.257812-32h-53.808594c-15.023437 12.191406-32.753906 20.59375-51.703125 24.503906zm0 0"
      fill="#c0f5f9"
    />
    <path d="m368 120h32v32h-32zm0 0" fill="#b9b9b9" />
    <path d="m432 120h32v32h-32zm0 0" fill="#b9b9b9" />
    <path d="m208 184h-32v-48c0-8.835938 7.164062-16 16-16s16 7.164062 16 16zm0 0" fill="#b9b9b9" />
    <path d="m304 120h32v32h-32zm0 0" fill="#b9b9b9" />
    <path d="m240 120h32v32h-32zm0 0" fill="#b9b9b9" />
    <path d="m368 128h24v24h-24zm0 0" fill="#c0f5f9" />
    <path d="m432 128h24v24h-24zm0 0" fill="#c0f5f9" />
    <path d="m304 128h24v24h-24zm0 0" fill="#c0f5f9" />
    <path d="m240 128h24v24h-24zm0 0" fill="#c0f5f9" />
    <path d="m0 184c20.488281 20.488281 48.28125 32 77.257812 32h386.742188v-32zm0 0" fill="#4799cf" />
    <path d="m0 248h464v32h-464zm0 0" fill="#64a8b2" />
    <path d="m256 24h48v16h-48zm0 0" fill="#c0f5f9" />
    <path d="m320 24h16v16h-16zm0 0" fill="#c0f5f9" />
    <path d="m352 48h48v16h-48zm0 0" fill="#c0f5f9" />
    <path d="m416 48h16v16h-16zm0 0" fill="#c0f5f9" />
    <path d="m392 8h48v16h-48zm0 0" fill="#c0f5f9" />
    <path d="m456 8h16v16h-16zm0 0" fill="#c0f5f9" />
    <path d="m160 0h48v16h-48zm0 0" fill="#c0f5f9" />
    <path d="m224 0h16v16h-16zm0 0" fill="#c0f5f9" />
    <path d="m128 48h48v16h-48zm0 0" fill="#c0f5f9" />
    <path d="m192 48h16v16h-16zm0 0" fill="#c0f5f9" />
    <g fill="#3b869e">
      <path d="m423.054688 248 16 32h17.890624l-16-32zm0 0" />
      <path d="m391.054688 248 16 32h17.890624l-16-32zm0 0" />
      <path d="m359.054688 248 16 32h17.890624l-16-32zm0 0" />
      <path d="m327.054688 248 16 32h17.890624l-16-32zm0 0" />
      <path d="m295.054688 248 16 32h17.890624l-16-32zm0 0" />
      <path d="m263.054688 248 16 32h17.890624l-16-32zm0 0" />
      <path d="m231.054688 248 16 32h17.890624l-16-32zm0 0" />
      <path d="m199.054688 248 16 32h17.890624l-16-32zm0 0" />
      <path d="m167.054688 248 16 32h17.890624l-16-32zm0 0" />
      <path d="m135.054688 248 16 32h17.890624l-16-32zm0 0" />
      <path d="m103.054688 248 16 32h17.890624l-16-32zm0 0" />
      <path d="m71.054688 248 16 32h17.890624l-16-32zm0 0" />
      <path d="m39.054688 248 16 32h17.890624l-16-32zm0 0" />
      <path d="m7.054688 248 16 32h17.890624l-16-32zm0 0" />
    </g>
  </svg>
)

export const JapanTea = () => (
  <svg width="64" height="64" viewBox="0 -43 471.99631 471" xmlns="http://www.w3.org/2000/svg">
    <path
      d="m127.996094 192.5 32-16 48 40 8 2.75 1.503906 85.25h-9.503906l-29.222656-14.617188c-11.507813-5.75-18.777344-17.511718-18.777344-30.375v-5.839843c0-13.707031-4.664063-27.003907-13.222656-37.707031zm0 0"
      fill="#eaeae8"
    />
    <path
      d="m413.429688 384.5h-154.867188c-11.480469 0-22.082031-6.152344-27.78125-16.121094l-22.785156-39.878906v-136l32-32h192l32 32v136l-22.785156 39.878906c-5.695313 9.96875-16.300782 16.121094-27.78125 16.121094zm0 0"
      fill="#d9d9d9"
    />
    <path
      d="m207.996094 328.5h256l-22.785156 39.878906c-5.695313 9.96875-16.300782 16.121094-27.78125 16.121094h-154.867188c-11.480469 0-22.082031-6.152344-27.78125-16.121094zm0 0"
      fill="#cdcdcd"
    />
    <path
      d="m431.996094 160.5h-192v-16c0-8.839844 7.164062-16 16-16h160c8.835937 0 16 7.160156 16 16zm0 0"
      fill="#eaeae8"
    />
    <path
      d="m463.996094 200.5c-4.417969 0-8-3.582031-8-8v-56c0-66.277344-53.726563-120-120-120-66.273438 0-120 53.722656-120 120v56c0 4.417969-3.582032 8-8 8-4.417969 0-8-3.582031-8-8v-56c0-75.113281 60.890625-136 136-136s136 60.886719 136 136v56c0 4.417969-3.582032 8-8 8zm0 0"
      fill="#9c775c"
    />
    <path
      d="m92.582031 384.5h-72l-13.457031-35.882812c-6.902344-18.421876-8.835938-38.335938-5.601562-57.742188l3.058593-18.375h104l3.0625 18.398438c3.242188 19.40625 1.308594 39.324218-5.601562 57.742187zm0 0"
      fill="#eaeae8"
    />
    <path
      d="m44.582031 296.5h-43.785156c-.621094 5.308594-.859375 10.65625-.71875 16h44.503906c4.417969 0 8-3.582031 8-8 0-4.421875-3.582031-8-8-8zm0 0"
      fill="#cdcdcd"
    />
    <path
      d="m36.582031 328.5h-34.898437c.957031 5.421875 2.308594 10.769531 4.046875 16h30.851562c4.417969 0 8-3.582031 8-8 0-4.421875-3.582031-8-8-8zm0 0"
      fill="#cdcdcd"
    />
    <path
      d="m351.996094 128.5h-32l-3.902344-11.707031c-2.511719-7.535157-.546875-15.84375 5.070312-21.460938 8.191407-8.191406 21.472657-8.191406 29.664063 0 5.617187 5.617188 7.582031 13.925781 5.070313 21.460938zm0 0"
      fill="#9c775c"
    />
    <g fill="#b9b9b9">
      <path d="m231.996094 216.5h16v16h-16zm0 0" />
      <path d="m231.996094 248.5h16v16h-16zm0 0" />
      <path d="m231.996094 280.5h16v16h-16zm0 0" />
      <path d="m263.996094 216.5h16v16h-16zm0 0" />
      <path d="m263.996094 248.5h16v16h-16zm0 0" />
      <path d="m263.996094 280.5h16v16h-16zm0 0" />
      <path d="m295.996094 216.5h16v16h-16zm0 0" />
      <path d="m295.996094 248.5h16v16h-16zm0 0" />
      <path d="m295.996094 280.5h16v16h-16zm0 0" />
      <path d="m359.996094 216.5h16v16h-16zm0 0" />
      <path d="m359.996094 248.5h16v16h-16zm0 0" />
      <path d="m359.996094 280.5h16v16h-16zm0 0" />
      <path d="m391.996094 216.5h16v16h-16zm0 0" />
      <path d="m391.996094 248.5h16v16h-16zm0 0" />
      <path d="m391.996094 280.5h16v16h-16zm0 0" />
      <path d="m423.996094 216.5h16v16h-16zm0 0" />
      <path d="m423.996094 248.5h16v16h-16zm0 0" />
      <path d="m423.996094 280.5h16v16h-16zm0 0" />
      <path d="m327.996094 216.5h16v16h-16zm0 0" />
      <path d="m327.996094 248.5h16v16h-16zm0 0" />
      <path d="m327.996094 280.5h16v16h-16zm0 0" />
    </g>
    <path
      d="m55.996094 184.5c-4.417969 0-8-3.582031-8-8v-24c0-4.421875 3.582031-8 8-8 4.417968 0 8 3.578125 8 8v24c0 4.417969-3.582032 8-8 8zm0 0"
      fill="#fbdeaf"
    />
    <path
      d="m55.996094 232.5c-4.417969 0-8-3.582031-8-8v-16c0-4.421875 3.582031-8 8-8 4.417968 0 8 3.578125 8 8v16c0 4.417969-3.582032 8-8 8zm0 0"
      fill="#fbdeaf"
    />
    <path
      d="m87.996094 208.5c-4.417969 0-8-3.582031-8-8v-32c0-4.421875 3.582031-8 8-8 4.417968 0 8 3.578125 8 8v32c0 4.417969-3.582032 8-8 8zm0 0"
      fill="#fbdeaf"
    />
    <path
      d="m87.996094 248.5c-4.417969 0-8-3.582031-8-8v-8c0-4.421875 3.582031-8 8-8 4.417968 0 8 3.578125 8 8v8c0 4.417969-3.582032 8-8 8zm0 0"
      fill="#fbdeaf"
    />
    <path
      d="m23.996094 208.5c-4.417969 0-8-3.582031-8-8v-24c0-4.421875 3.582031-8 8-8 4.417968 0 8 3.578125 8 8v24c0 4.417969-3.582032 8-8 8zm0 0"
      fill="#fbdeaf"
    />
    <path
      d="m23.996094 248.5c-4.417969 0-8-3.582031-8-8v-8c0-4.421875 3.582031-8 8-8 4.417968 0 8 3.578125 8 8v8c0 4.417969-3.582032 8-8 8zm0 0"
      fill="#fbdeaf"
    />
    <path
      d="m119.996094 112.5c-4.417969 0-8-3.582031-8-8v-24c0-4.421875 3.582031-8 8-8 4.417968 0 8 3.578125 8 8v24c0 4.417969-3.582032 8-8 8zm0 0"
      fill="#fbd699"
    />
    <path
      d="m119.996094 152.5c-4.417969 0-8-3.582031-8-8v-8c0-4.421875 3.582031-8 8-8 4.417968 0 8 3.578125 8 8v8c0 4.417969-3.582032 8-8 8zm0 0"
      fill="#fbd699"
    />
    <path
      d="m151.996094 152.5c-4.417969 0-8-3.582031-8-8v-32c0-4.421875 3.582031-8 8-8 4.417968 0 8 3.578125 8 8v32c0 4.417969-3.582032 8-8 8zm0 0"
      fill="#fbd699"
    />
    <path
      d="m151.996094 88.5c-4.417969 0-8-3.582031-8-8v-8c0-4.421875 3.582031-8 8-8 4.417968 0 8 3.578125 8 8v8c0 4.417969-3.582032 8-8 8zm0 0"
      fill="#fbd699"
    />
    <path d="m14.582031 368.5 6 16h72l6-16zm0 0" fill="#e0e0de" />
    <path
      d="m441.210938 368.378906h-210.398438c5.691406 9.957032 16.28125 16.109375 27.75 16.121094h154.867188c11.480468 0 22.085937-6.152344 27.78125-16.121094zm0 0"
      fill="#c9c9c9"
    />
  </svg>
)

export const ManekiNeko = () => (
  <svg height="463pt" viewBox="-72 0 463 463.97561" width="463pt" xmlns="http://www.w3.org/2000/svg">
    <path d="m56.484375 255.976562h176v208h-176zm0 0" fill="#ebebeb" />
    <path
      d="m16.683594 353.152344c-16.042969 13.375-20.785156 36.027344-11.445313 54.710937l8.046875 16.113281-3.417968 3.414063c-7.714844 7.714844-10.40625 19.125-6.957032 29.472656l2.398438 7.113281h72v-112l-15.089844-6.03125c-15.347656-6.148437-32.832031-3.382812-45.535156 7.207032zm0 0"
      fill="#ebebeb"
    />
    <path
      d="m155.316406 371.976562c-11.496094-21.320312-38.058594-29.339843-59.433594-17.941406-21.371093 11.398438-29.511718 37.925782-18.214843 59.347656l18.816406 35.289063c3.261719 6.105469 7.921875 11.347656 13.601563 15.304687h50.460937c18.210937-12.753906 24.046875-37.085937 13.601563-56.714843zm0 0"
      fill="#dededc"
    />
    <path
      d="m256.484375 119.976562v17.75c.019531 6.5625 2.949219 12.773438 8 16.960938v55.625c0 18.179688-10.273437 34.796875-26.535156 42.925781l-5.464844 2.738281v48l32.230469-4c18.398437-2.296874 32.515625-17.421874 33.546875-35.929687l-1.664063-105.070313c9.414063-2.785156 15.878906-11.429687 15.886719-21.25v-17.75zm0 0"
      fill="#dededc"
    />
    <path
      d="m232.484375 287.976562-59.886719 5.445313c-18.703125 1.699219-37.523437 1.699219-56.226562 0l-59.886719-5.445313v-32h176zm0 0"
      fill="#e85244"
    />
    <path
      d="m216.484375 260.902344h-40l-17.6875 8.800781c-9.007813 4.503906-19.613281 4.503906-28.625 0l-17.6875-8.800781h-40c-26.507813 0-48-21.488282-48-48v-68.390625c0-39.871094 26.804687-74.761719 65.328125-85.03125l32-8.535157c14.855469-3.960937 30.488281-3.960937 45.34375 0l32 8.535157c38.523438 10.269531 65.328125 45.160156 65.328125 85.03125v68.390625c0 26.511718-21.488281 48-48 48zm0 0"
      fill="#ebebeb"
    />
    <path
      d="m121.507812 231.03125c-4.375.003906-8.6875-1.015625-12.597656-2.976562-2.582031-1.261719-4.285156-3.8125-4.464844-6.679688-.179687-2.867188 1.191407-5.613281 3.589844-7.191406 2.402344-1.574219 5.464844-1.746094 8.027344-.441406 5.53125 2.753906 12.25.96875 15.6875-4.167969l6.078125-9.109375c1.566406-2.105469 4.035156-3.34375 6.65625-3.34375s5.089844 1.238281 6.65625 3.34375l6.078125 9.109375c3.4375 5.136719 10.15625 6.921875 15.691406 4.167969 2.558594-1.304688 5.621094-1.132813 8.023438.441406 2.398437 1.578125 3.769531 4.324218 3.589844 7.191406-.179688 2.867188-1.882813 5.417969-4.460938 6.679688-12.40625 6.179687-27.464844 2.46875-35.578125-8.765626-5.324219 7.378907-13.875 11.75-22.976563 11.742188zm0 0"
      fill="#e85244"
    />
    <path
      d="m136.484375 47.976562-88.414063-46.066406c-4.957031-2.664062-10.949218-2.535156-15.78125.347656-4.832031 2.882813-7.796874 8.089844-7.804687 13.71875v128zm0 0"
      fill="#ebebeb"
    />
    <path d="m48.484375 29.207031 48 32-48 32zm0 0" fill="#e85244" />
    <path
      d="m152.484375 47.976562 88.417969-46.066406c4.953125-2.664062 10.945312-2.535156 15.777344.347656 4.832031 2.882813 7.796874 8.089844 7.804687 13.71875v128zm0 0"
      fill="#ebebeb"
    />
    <path d="m240.484375 29.207031-48 32 48 32zm0 0" fill="#e85244" />
    <g fill="#b9b9b9">
      <path d="m64.484375 199.976562c-.859375-.003906-1.710937-.144531-2.527344-.417968l-48-16c-4.199219-1.394532-6.46875-5.929688-5.074219-10.128906 1.398438-4.195313 5.933594-6.464844 10.128907-5.070313l48 16c3.765625 1.210937 6.082031 4.992187 5.449219 8.894531-.628907 3.90625-4.019532 6.761719-7.976563 6.722656zm0 0" />
      <path d="m16.484375 223.976562c-4.15625-.011718-7.609375-3.203124-7.949219-7.34375-.34375-4.140624 2.542969-7.851562 6.636719-8.546874l48-8c4.300781-.613282 8.304687 2.316406 9.019531 6.601562.71875 4.285156-2.113281 8.355469-6.378906 9.175781l-48 8c-.4375.078125-.882812.113281-1.328125.113281zm0 0" />
      <path d="m24.484375 263.976562c-3.398437 0-6.425781-2.144531-7.550781-5.351562s-.101563-6.773438 2.550781-8.898438l40-32c3.449219-2.761718 8.488281-2.203124 11.25 1.25 2.757813 3.449219 2.199219 8.484376-1.25 11.246094l-40 32c-1.417969 1.136719-3.183594 1.753906-5 1.753906zm0 0" />
      <path d="m224.484375 199.976562c-3.917969-.011718-7.25-2.851562-7.878906-6.71875-.625-3.867187 1.636719-7.621093 5.351562-8.867187l48-16c4.195313-1.394531 8.730469.875 10.128907 5.074219 1.394531 4.195312-.875 8.730468-5.074219 10.125l-48 16c-.816407.265625-1.667969.394531-2.527344.386718zm0 0" />
      <path d="m272.484375 223.976562c-.445313 0-.890625-.035156-1.328125-.113281l-48-8c-4.265625-.820312-7.097656-4.890625-6.378906-9.175781.714844-4.285156 4.71875-7.214844 9.019531-6.601562l48 8c4.097656.695312 6.980469 4.40625 6.640625 8.546874-.34375 4.140626-3.796875 7.332032-7.953125 7.34375zm0 0" />
      <path d="m264.484375 263.976562c-1.816406 0-3.582031-.617187-5-1.753906l-40-32c-3.449219-2.761718-4.007813-7.796875-1.246094-11.246094 2.761719-3.453124 7.796875-4.011718 11.246094-1.25l40 32c2.65625 2.125 3.679687 5.691407 2.554687 8.898438-1.128906 3.207031-4.15625 5.351562-7.554687 5.351562zm0 0" />
    </g>
    <path
      d="m95.789062 341.164062c21.441407-11.433593 48.09375-3.324218 59.527344 18.117188l18.824219 35.296875c11.433594 21.441406 3.324219 48.09375-18.117187 59.527344-21.445313 11.4375-48.09375 3.324219-59.53125-18.117188l-18.824219-35.292969c-11.433594-21.441406-3.324219-48.09375 18.121093-59.53125zm0 0"
      fill="#ea9d2d"
    />
    <path
      d="m168.484375 287.976562c0 13.253907-10.746094 24-24 24s-24-10.746093-24-24c0-13.253906 10.746094-24 24-24s24 10.746094 24 24zm0 0"
      fill="#da922a"
    />
    <path
      d="m304.484375 103.976562c-4.613281.007813-8.992187 2.023438-12 5.519532-3-3.503906-7.386719-5.523438-12-5.523438s-8.996094 2.019532-12 5.523438c-4.359375-5.050782-11.402344-6.851563-17.648437-4.511719-6.25 2.335937-10.378907 8.320313-10.351563 14.992187v8c-.027344 6.671876 4.101563 12.652344 10.351563 14.992188 6.246093 2.339844 13.289062.539062 17.648437-4.511719 3.003906 3.503907 7.386719 5.519531 12 5.519531s9-2.015624 12-5.519531c4.359375 5.050781 11.402344 6.851563 17.652344 4.511719 6.246093-2.339844 10.375-8.320312 10.347656-14.992188v-8c0-8.835937-7.164063-16-16-16zm0 0"
      fill="#cbcbcb"
    />
    <path
      d="m264.371094 141.757812c-.394532-13.101562-3.722656-25.949218-9.734375-37.597656-8.050781.914063-14.132813 7.714844-14.152344 15.816406v8c0 8.835938 7.164063 16 16 16 2.78125-.023437 5.503906-.789062 7.886719-2.21875zm0 0"
      fill="#dededc"
    />
    <path
      d="m33.691406 265.023438c-10.71875 14.300781-13.40625 33.074218-7.132812 49.808593l6.628906 17.6875c4.390625 11.710938 15.589844 19.464844 28.097656 19.457031h11.199219c10.027344 0 19.390625-5.011718 24.953125-13.351562 4.535156-6.800781 6.09375-15.160156 4.320312-23.136719l-5.464843-24.59375c-4.539063-20.417969-22.660157-34.9375-43.578125-34.917969h-.917969c-7.121094 0-13.828125 3.351563-18.105469 9.046876zm0 0"
      fill="#f0f0f0"
    />
    <path
      d="m240.484375 343.976562h-16.863281c-12.777344 0-23.136719 10.355469-23.136719 23.136719v8.863281zm0 0"
      fill="#dededc"
    />
    <path
      d="m272.285156 353.152344c16.042969 13.375 20.785156 36.027344 11.449219 54.710937l-8.050781 16.113281 3.417968 3.414063c7.714844 7.714844 10.40625 19.125 6.960938 29.472656l-2.378906 7.113281h-72v-95.753906c.003906-9.8125 5.976562-18.636718 15.089844-22.277344 15.34375-6.140624 32.816406-3.371093 45.511718 7.207032zm0 0"
      fill="#ebebeb"
    />
    <path
      d="m155.316406 359.28125c-11.433594-21.441406-38.085937-29.554688-59.527344-18.121094-3.875 2.25-7.480468 4.933594-10.746093 8-1.355469 9.558594.691406 19.285156 5.785156 27.488282l18.824219 35.328124c11.433594 21.441407 38.085937 29.554688 59.527344 18.117188 3.402343-1.835938 6.554687-4.109375 9.367187-6.757812 1.863281-9.820313.308594-19.984376-4.40625-28.800782zm0 0"
      fill="#f3af3e"
    />
    <path d="m108.910156 375.128906 7.15625-14.3125 16 8-7.15625 14.3125zm0 0" fill="#da922a" />
    <path d="m124.910156 407.128906 7.15625-14.3125 16 8-7.15625 14.3125zm0 0" fill="#da922a" />
    <path
      d="m304.484375 95.976562c-4.613281.007813-8.992187 2.023438-12 5.519532-3-3.503906-7.386719-5.523438-12-5.523438s-8.996094 2.019532-12 5.523438c-4.359375-5.050782-11.402344-6.851563-17.648437-4.511719-6.25 2.335937-10.378907 8.320313-10.351563 14.992187v8c-.027344 6.671876 4.101563 12.652344 10.351563 14.992188 6.246093 2.339844 13.289062.539062 17.648437-4.511719 3.003906 3.503907 7.386719 5.519531 12 5.519531s9-2.015624 12-5.519531c4.359375 5.050781 11.402344 6.851563 17.652344 4.511719 6.246093-2.339844 10.375-8.320312 10.347656-14.992188v-8c0-8.835937-7.164063-16-16-16zm0 0"
      fill="#ebebeb"
    />
    <path
      d="m88.484375 156.894531c-8.835937 0-16-7.164062-16-16 0-8.835937 7.164063-16 16-16s16 7.164063 16 16c0 8.835938-7.164063 16-16 16zm0-16"
      fill="#fbb540"
    />
    <path
      d="m200.484375 156.894531c-8.835937 0-16-7.164062-16-16 0-8.835937 7.164063-16 16-16s16 7.164063 16 16c0 8.835938-7.164063 16-16 16zm0-16"
      fill="#fbb540"
    />
    <path
      d="m100.484375 140.902344c0 6.628906-5.371094 12-12 12s-12-5.371094-12-12c0-6.625 5.371094-12 12-12s12 5.375 12 12zm0 0"
      fill="#6f7478"
    />
    <path
      d="m212.484375 140.902344c0 6.628906-5.371094 12-12 12s-12-5.371094-12-12c0-6.625 5.371094-12 12-12s12 5.375 12 12zm0 0"
      fill="#6f7478"
    />
    <path
      d="m144.484375 207.566406c-5.039063.023438-9.792969-2.351562-12.800781-6.398437l-8-10.65625c-3.636719-4.847657-4.21875-11.335938-1.507813-16.753907 2.707031-5.421874 8.25-8.847656 14.308594-8.847656h16c6.0625 0 11.601563 3.425782 14.3125 8.847656 2.707031 5.417969 2.125 11.90625-1.511719 16.753907l-8 10.65625c-3.007812 4.046875-7.757812 6.421875-12.800781 6.398437zm0 0"
      fill="#ffc5c3"
    />
  </svg>
)

export const Ninja = () => (
  <svg width="64" height="64" viewBox="0 -19 454.41437 454" xmlns="http://www.w3.org/2000/svg">
    <path
      d="m302.820312 280.085938 63.085938-102.078126 27.222656 16.820313-63.085937 102.082031zm0 0"
      fill="#428dc5"
    />
    <path d="m61.285156 194.824219 27.222656-16.824219 63.085938 102.078125-27.222656 16.824219zm0 0" fill="#b6534f" />
    <path d="m95.589844 272.207031h256v144h-256zm0 0" fill="#1f6c8c" />
    <path d="m95.589844 272.207031v144h-80v-64c0-44.183593 35.816406-80 80-80zm0 0" fill="#176177" />
    <path d="m351.589844 272.207031c44.183594 0 80 35.816407 80 80v64h-80zm0 0" fill="#176177" />
    <path d="m236.039062 352.207031-44.449218-80h-40l57.777344 104zm0 0" fill="#b9b9b9" />
    <path
      d="m359.097656 173.792969 40.832032 25.234375c7.515624 4.644531 9.84375 14.507812 5.199218 22.023437-4.648437 7.515625-14.507812 9.84375-22.023437 5.199219l-40.832031-25.234375c-7.515626-4.648437-9.84375-14.507813-5.199219-22.023437 4.644531-7.515626 14.503906-9.84375 22.023437-5.199219zm0 0"
      fill="#cdcdcd"
    />
    <path
      d="m393.125 194.824219-27.199219-16.800781 58.878907-95.273438c4.648437-7.515625 14.507812-9.84375 22.023437-5.199219 7.515625 4.648438 9.84375 14.507813 5.195313 22.023438zm0 0"
      fill="#9c9c9c"
    />
    <g fill="#cdcdcd">
      <path d="m443.589844 113.160156-27.199219-16.800781 8.414063-13.601563c4.648437-7.515624 14.507812-9.84375 22.023437-5.199218 7.515625 4.648437 9.84375 14.507812 5.195313 22.023437zm0 0" />
      <path d="m407.738281 119.214844 15.574219-3.671875 3.671875 15.582031-15.570313 3.671875zm0 0" />
      <path d="m396.359375 137.640625 15.574219-3.671875 3.671875 15.582031-15.574219 3.667969zm0 0" />
      <path d="m383.746094 158.042969 15.570312-3.667969 3.675782 15.582031-15.574219 3.667969zm0 0" />
    </g>
    <path
      d="m112.039062 200.964844-40.847656 25.246094c-7.519531 4.648437-17.382812 2.320312-22.03125-5.199219-4.648437-7.519531-2.320312-17.382813 5.199219-22.03125l40.847656-25.25c7.519531-4.644531 17.386719-2.316407 22.03125 5.203125 4.648438 7.519531 2.320313 17.382812-5.199219 22.03125zm0 0"
      fill="#f7ba61"
    />
    <path
      d="m61.285156 194.824219 27.199219-16.800781-58.863281-95.296876c-4.644532-7.523437-14.511719-9.851562-22.03125-5.199218-7.5195315 4.644531-9.847656 14.507812-5.199219 22.023437zm0 0"
      fill="#cdcdcd"
    />
    <path
      d="m10.789062 113.160156 27.199219-16.800781-8.40625-13.601563c-4.632812-7.507812-14.472656-9.851562-21.992187-5.230468-7.5195315 4.644531-9.847656 14.507812-5.199219 22.023437zm0 0"
      fill="#f7ba61"
    />
    <path d="m27.417969 131.117188 3.675781-15.578126 15.570312 3.671876-3.671874 15.578124zm0 0" fill="#9c9c9c" />
    <path d="m38.808594 149.542969 3.671875-15.582031 15.582031 3.671874-3.671875 15.582032zm0 0" fill="#9c9c9c" />
    <path d="m51.414062 169.960938 3.671876-15.582032 15.582031 3.671875-3.671875 15.582031zm0 0" fill="#9c9c9c" />
    <path d="m215.589844 416.207031h-40l80-144h40zm0 0" fill="#cdcdcd" />
    <path d="m255.589844 272.207031-32 24-32-24v-56h64zm0 0" fill="#176177" />
    <path d="m191.589844 272.207031 32 56 32-56zm0 0" fill="#176177" />
    <path
      d="m223.589844 16.207031c48.601562 0 88 39.398438 88 88v48c0 48.601563-39.398438 88-88 88-48.601563 0-88-39.398437-88-88v-48c0-48.601562 39.398437-88 88-88zm0 0"
      fill="#1f6c8c"
    />
    <path
      d="m311.589844 152.207031h-56l-17.6875-8.847656c-9.011719-4.503906-19.613282-4.503906-28.625 0l-17.6875 8.847656h-56v-64h176zm0 0"
      fill="#f9d7c0"
    />
    <path
      d="m263.589844 136.207031c-4.417969 0-8-3.582031-8-8v-8c0-4.417969 3.582031-8 8-8 4.417968 0 8 3.582031 8 8v8c0 4.417969-3.582032 8-8 8zm0 0"
      fill="#533222"
    />
    <path
      d="m183.589844 136.207031c-4.417969 0-8-3.582031-8-8v-8c0-4.417969 3.582031-8 8-8 4.417968 0 8 3.582031 8 8v8c0 4.417969-3.582032 8-8 8zm0 0"
      fill="#533222"
    />
    <path
      d="m318.492188 88.207031h-189.804688c-5.023438 0-9.097656-4.070312-9.097656-9.097656-.011719-9.320313 4.644531-18.027344 12.398437-23.199219l11.601563-7.703125c4.617187-27.699219 28.582031-48 56.664062-48h46.671875c28.082031 0 52.046875 20.300781 56.664063 48l11.601562 7.734375c7.753906 5.171875 12.410156 13.878906 12.398438 23.203125-.015625 5.007813-4.085938 9.0625-9.097656 9.0625zm0 0"
      fill="#1f6c8c"
    />
    <path
      d="m143.589844 48.207031h40c4.417968 0 8-3.582031 8-8s-3.582032-8-8-8h-34.800782c-2.507812 5.050781-4.261718 10.441407-5.199218 16zm0 0"
      fill="#176177"
    />
    <path
      d="m231.589844 48.207031h-16c-4.417969 0-8-3.582031-8-8s3.582031-8 8-8h16c4.417968 0 8 3.582031 8 8s-3.582032 8-8 8zm0 0"
      fill="#176177"
    />
    <path
      d="m247.589844 120.207031c-3.839844.003907-7.136719-2.71875-7.863282-6.488281-.726562-3.769531 1.328126-7.523438 4.894532-8.945312l40-16c4.105468-1.636719 8.761718.359374 10.402344 4.464843 1.636718 4.105469-.359376 8.761719-4.464844 10.402344l-40 16c-.945313.378906-1.953125.570313-2.96875.566406zm0 0"
      fill="#8f563b"
    />
    <path
      d="m199.589844 120.207031c-1.015625.003907-2.023438-.1875-2.96875-.566406l-40-16c-4.105469-1.640625-6.101563-6.296875-4.464844-10.402344 1.640625-4.105469 6.296875-6.101562 10.402344-4.464843l40 16c3.566406 1.421874 5.621094 5.175781 4.894531 8.945312s-4.023437 6.492188-7.863281 6.488281zm0 0"
      fill="#8f563b"
    />
  </svg>
)

export const JapanEarthquake = () => (
  <svg height="64" viewBox="0 -13 512 512" width="64" xmlns="http://www.w3.org/2000/svg">
    <path
      d="m428.898438 293.296875c-.679688 0-1.371094-.089844-2.058594-.277344-4.140625-1.136719-6.578125-5.410156-5.441406-9.550781l18.878906-68.902344c1.132812-4.140625 5.410156-6.578125 9.550781-5.445312 4.140625 1.136718 6.578125 5.414062 5.441406 9.554687l-18.878906 68.898438c-.945313 3.453125-4.078125 5.722656-7.492187 5.722656zm0 0"
      fill="#a05e44"
    />
    <path
      d="m313.875 295.710938-255.816406 28.851562-23.554688-208.839844 118.777344-95.402344 137.042969 66.550782zm0 0"
      fill="#efefef"
    />
    <path
      d="m226.6875 114.332031 21.296875 188.808594 65.890625-7.429687-23.550781-208.839844-137.042969-66.550782-37.382812 30.027344 99.824218 48.472656c6.070313 2.949219 10.210938 8.804688 10.964844 15.511719zm0 0"
      fill="#d8d8d8"
    />
    <path
      d="m6.964844 113.605469 138.484375-111.226563c3.25-2.613281 7.71875-3.117187 11.472656-1.296875l159.777344 77.589844c5.742187 2.789063 9.660156 8.328125 10.375 14.671875 1.644531 14.589844-13.445313 25.261719-26.652344 18.847656l-138.558594-67.285156c-3.753906-1.824219-8.21875-1.320312-11.472656 1.292969l-120.09375 96.457031c-11.445313 9.191406-28.535156 2.152344-30.179687-12.4375-.714844-6.347656 1.867187-12.613281 6.847656-16.613281zm0 0"
      fill="#fc4e51"
    />
    <path
      d="m178.597656 310.96875-84.933594 9.578125-15.449218-136.980469c-.496094-4.378906 2.652344-8.328125 7.03125-8.820312l69.082031-7.792969c4.378906-.492187 8.328125 2.65625 8.820313 7.035156zm0 0"
      fill="#912c2c"
    />
    <path
      d="m141.480469 254.167969c-3.902344 0-7.265625-2.929688-7.714844-6.902344l-1.777344-15.761719c-.480469-4.265625 2.585938-8.117187 6.855469-8.597656 4.257812-.484375 8.113281 2.585938 8.59375 6.855469l1.78125 15.761719c.480469 4.265624-2.589844 8.117187-6.855469 8.597656-.296875.03125-.589843.046875-.882812.046875zm0 0"
      fill="#d8d8d8"
    />
    <path
      d="m267.597656 229.386719-60.125 6.78125c-3.90625.441406-7.425781-2.367188-7.867187-6.269531l-6.78125-60.128907c-.4375-3.902343 2.367187-7.425781 6.273437-7.863281l60.125-6.78125c3.902344-.441406 7.425782 2.367188 7.867188 6.269531l6.777344 60.125c.441406 3.90625-2.363282 7.425781-6.269532 7.867188zm0 0"
      fill="#33d8dd"
    />
    <path
      d="m415.234375 272.738281-40.15625 34.683594c-2.867187 2.476563-3.414063 6.707031-1.269531 9.828125l23.664062 34.472656c2.394532 3.488282 1.851563 8.207032-1.277344 11.058594l-92.539062 79.984375c-3.019531 2.609375-7.335938-1.097656-5.210938-4.476563l47.242188-75.09375c1.101562-2.90625.523438-6.183593-1.507812-8.539062l-31.484376-36.484375 27.75-30.746094c2.21875-2.460937.21875-6.363281-3.078124-5.996093l-327.726563 36.578124c-5.488281.613282-9.640625 5.253907-9.640625 10.777344v146.21875c0 5.992188 4.855469 10.847656 10.84375 10.847656h490.308594c5.992187 0 10.847656-4.859374 10.847656-10.847656v-192.539062c0-2.84375-2.472656-5.054688-5.296875-4.738282zm0 0"
      fill="#e88c38"
    />
    <path
      d="m506.703125 277.726562-60.382813-3.292968v200.570312c0 5.992188-4.855468 10.847656-10.84375 10.847656h65.679688c5.988281 0 10.84375-4.859374 10.84375-10.847656v-192.539062c0-2.84375-2.472656-5.054688-5.296875-4.738282zm0 0"
      fill="#cc7a2f"
    />
    <path
      d="m337.367188 281.429688-327.726563 36.578124c-5.488281.613282-9.640625 5.253907-9.640625 10.777344v24.285156l139.601562-15.582031 173.09375-19.320312 27.75-30.742188c2.21875-2.464843.21875-6.363281-3.078124-5.996093zm0 0"
      fill="#cebb84"
    />
    <path
      d="m506.703125 277.726562-91.46875-4.988281-40.15625 34.683594c-1.265625 1.09375-2.074219 2.523437-2.40625 4.054687l139.328125-.355468v-28.65625c0-2.84375-2.472656-5.054688-5.296875-4.738282zm0 0"
      fill="#cebb84"
    />
    <path
      d="m506.703125 277.726562-60.382813-3.292968v36.855468l65.679688-.167968v-28.65625c0-2.84375-2.472656-5.054688-5.296875-4.738282zm0 0"
      fill="#b7a169"
    />
    <path
      d="m467.214844 134.570312-77.648438 75.136719c-1.894531 1.835938-1.078125 5.035157 1.46875 5.730469l106.15625 29.089844c2.542969.695312 4.878906-1.640625 4.183594-4.183594l-28.507812-104.222656c-.679688-2.5-3.789063-3.351563-5.652344-1.550782zm0 0"
      fill="#8fd143"
    />
    <path
      d="m474.851562 106.695312-62.066406 60.058594c-1.894531 1.835938-1.074218 5.035156 1.46875 5.730469l84.851563 23.25c2.546875.699219 4.882812-1.636719 4.1875-4.183594l-22.785157-83.304687c-.683593-2.5-3.792968-3.351563-5.65625-1.550782zm0 0"
      fill="#7bb72e"
    />
    <path
      d="m482.058594 80.398438-46.042969 44.550781c-1.894531 1.835937-1.074219 5.035156 1.46875 5.734375l62.945313 17.246094c2.542968.695312 4.882812-1.640626 4.183593-4.183594l-16.902343-61.800782c-.683594-2.496093-3.789063-3.347656-5.652344-1.546874zm0 0"
      fill="#8fd143"
    />
  </svg>
)

export const JapanEarthquake2 = () => (
  <svg x="0px" y="0px" viewBox="0 0 512 512" width="64" height="64">
    <g>
      <g>
        <g>
          <path
            fill="#E58D23"
            d="M139.146,460.173H46.8c-11.046,0-20-8.954-20-20V71.827c0-11.046,8.954-20,20-20h92.347     c11.046,0,20,8.954,20,20v368.345C159.146,451.218,150.192,460.173,139.146,460.173z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            fill="#A56021"
            d="M139.146,380.159h-37.35c-5.523,0-10-4.477-10-10V143.384c0-5.523,4.477-10,10-10h37.35     c5.523,0,10,4.477,10,10v226.775C149.146,375.682,144.669,380.159,139.146,380.159z"
          />
        </g>
      </g>
      <g>
        <g>
          <rect x="91.796" y="159.523" fill="#FFEACF" width="346.749" height="193.992" />
        </g>
      </g>
      <g>
        <g>
          <path
            fill="#BF6C0D"
            d="M475.2,380.159h-26.656c-5.523,0-10-4.477-10-10V143.384c0-5.523,4.477-10,10-10H475.2     c5.523,0,10,4.477,10,10v226.775C485.2,375.682,480.723,380.159,475.2,380.159z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            fill="#BF6C0D"
            d="M66.8,440.172V71.827c0-11.046,8.954-20,20-20h-40c-11.046,0-20,8.954-20,20v368.345     c0,11.046,8.954,20,20,20h40C75.754,460.172,66.8,451.218,66.8,440.172z"
          />
        </g>
      </g>
      <g>
        <g>
          <rect x="91.796" y="159.523" fill="#FFD59A" width="40" height="193.992" />
        </g>
      </g>
      <g>
        <g>
          <path
            fill="#FF4B34"
            d="M202.686,325.157c-2.168,0-4.11-1.406-4.768-3.505l-23.484-74.925l-39.167,49.065     c-0.924,1.158-2.314,1.847-3.795,1.88c-1.499,0.029-2.901-0.592-3.876-1.708l-35.385-40.464     c-1.818-2.078-1.606-5.237,0.472-7.055c2.079-1.816,5.238-1.606,7.055,0.473l31.447,35.959l41.301-51.738     c1.153-1.445,3.013-2.131,4.827-1.796c1.817,0.341,3.299,1.655,3.852,3.42l19.711,62.888l14.101-98.036     c0.353-2.449,2.443-4.272,4.918-4.288c0.011,0,0.021,0,0.032,0c2.461,0,4.558,1.792,4.939,4.227l14.836,94.725l11.567-48.701     c0.55-2.316,2.659-3.933,5.038-3.842c2.38,0.083,4.371,1.832,4.76,4.181l7.89,47.7l51.566-104.993     c0.884-1.8,2.741-2.923,4.76-2.788c2.002,0.108,3.745,1.402,4.429,3.287l33.47,92.255V252.21c0-2.762,2.238-5,5-5h34.543     c2.762,0,5,2.238,5,5s-2.238,5-5,5H373.18v52.609c0,2.428-1.743,4.504-4.135,4.925c-2.39,0.417-4.736-0.938-5.565-3.22     l-39.162-107.945l-53.262,108.444c-0.959,1.951-3.074,3.06-5.217,2.742c-2.15-0.316-3.85-1.986-4.204-4.13l-6.38-38.57     l-11.697,49.247c-0.553,2.325-2.683,3.932-5.059,3.841c-2.388-0.093-4.375-1.862-4.746-4.223l-13.619-86.947l-12.497,86.886     c-0.333,2.311-2.22,4.084-4.547,4.271C202.955,325.152,202.82,325.157,202.686,325.157z"
          />
        </g>
      </g>
      <g>
        <g>
          <circle fill="#FF4B34" cx="408.354" cy="250.818" r="19.664" />
        </g>
      </g>
      <g>
        <g>
          <path
            fill="#FFF4C1"
            d="M408.354,262.202c-6.278,0-11.385-5.107-11.385-11.384c0-6.278,5.107-11.385,11.385-11.385     c6.277,0,11.384,5.107,11.384,11.385C419.738,257.095,414.631,262.202,408.354,262.202z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            fill="#8C4C17"
            d="M459.545,370.159V143.384c0-5.523,4.477-10,10-10h-21c-5.523,0-10,4.477-10,10v226.775     c0,5.523,4.477,10,10,10h21C464.022,380.159,459.545,375.682,459.545,370.159z"
          />
        </g>
      </g>
      <g>
        <g>
          <circle fill="#FFD59A" cx="159.146" cy="190.827" r="15" />
        </g>
      </g>
      <g>
        <g>
          <circle fill="#BF6C0D" cx="91.796" cy="427.159" r="15" />
        </g>
      </g>
      <g>
        <g>
          <path
            fill="#FFA730"
            d="M144.146,445.299c-3.313,0-6-2.687-6-6v-44.452c0-3.313,2.687-6,6-6s6,2.687,6,6v44.452     C150.146,442.612,147.46,445.299,144.146,445.299z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            fill="#E58D23"
            d="M470.545,233.259c-3.313,0-6-2.687-6-6v-70c0-3.313,2.687-6,6-6s6,2.687,6,6v70     C476.545,230.572,473.858,233.259,470.545,233.259z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            fill="#FFA730"
            d="M143.146,121.299c-3.313,0-6-2.687-6-6V70.847c0-3.313,2.687-6,6-6s6,2.687,6,6v44.452     C149.146,118.612,146.46,121.299,143.146,121.299z"
          />
        </g>
      </g>
    </g>
  </svg>
)

export const Volcano = () => (
  <svg id="Layer_3" height="64" viewBox="0 0 64 64" width="64" xmlns="http://www.w3.org/2000/svg" data-name="Layer 3">
    <path
      d="m56 56h-29.673a3.865 3.865 0 0 1 -3.179-1.791 4.992 4.992 0 0 0 -4.148-2.209 4.919 4.919 0 0 0 -2.158.5 4.176 4.176 0 0 1 -3.684 0 4.919 4.919 0 0 0 -2.158-.5 5.013 5.013 0 0 0 -1.083.118l11.373-16.677a21.35 21.35 0 0 0 3.71-12.027 3.415 3.415 0 0 1 1-2.414 3.415 3.415 0 0 1 2.414-1h7.172a3.415 3.415 0 0 1 2.414 1 3.415 3.415 0 0 1 1 2.414 22.105 22.105 0 0 0 3.6 12.1z"
      fill="#686868"
    />
    <path
      d="m45.293 57.707-1-1a28.352 28.352 0 0 1 -7.2-12.231l-.057-.2 1.922-.55.058.2a26.356 26.356 0 0 0 6.688 11.367l1 1z"
      fill="#474747"
    />
    <path
      d="m21.538 54.4-1.613-1.181c1.9-2.6 4.232-6.412 5.055-9.294l.059-.2 1.922.55-.058.2c-1.127 3.947-4.391 8.596-5.365 9.925z"
      fill="#474747"
    />
    <path d="m31 44h2v12h-2z" fill="#474747" />
    <path
      d="m42.285 35h-1.785a2.5 2.5 0 0 0 -2.5 2.5 2.5 2.5 0 0 1 -2.5 2.5 2.5 2.5 0 0 1 -2.5-2.5v-1a2.5 2.5 0 0 0 -2.5-2.5 2.5 2.5 0 0 0 -2.5 2.5v1.5a3 3 0 0 1 -3 3 3 3 0 0 1 -3-3v-3.673a21.35 21.35 0 0 0 3-10.913 3.415 3.415 0 0 1 1-2.414 3.415 3.415 0 0 1 2.414-1h7.172a3.415 3.415 0 0 1 2.414 1 3.415 3.415 0 0 1 1 2.414 22.1 22.1 0 0 0 3.281 11.586z"
      fill="#f0ae42"
    />
    <path
      d="m26.327 56a3.865 3.865 0 0 1 -3.179-1.791 4.992 4.992 0 0 0 -4.148-2.209 4.919 4.919 0 0 0 -2.158.5 4.176 4.176 0 0 1 -3.684 0 4.919 4.919 0 0 0 -2.158-.5 5 5 0 0 0 -4.182 2.259 3.715 3.715 0 0 1 -3.085 1.741h-.05a1.674 1.674 0 0 0 -1.683 1.672v4.328h60v-4a2 2 0 0 0 -2-2z"
      fill="#939393"
    />
    <path
      d="m36 17v-3.184a2.895 2.895 0 0 0 2.836-.46 3.988 3.988 0 1 0 4.111-6.83 3.971 3.971 0 0 0 .053-.526 3.995 3.995 0 0 0 -7.267-2.3 4.943 4.943 0 0 0 -7.466 0 3.988 3.988 0 0 0 -7 3.706 3.993 3.993 0 1 0 4.576 6.4 3.614 3.614 0 0 0 2.157.053v3.141z"
      fill="#fce797"
    />
    <g fill="#f9d266">
      <path d="m18.621 25.379a7.047 7.047 0 0 0 1.852-3.271l.527-2.108-2.108.527a7.047 7.047 0 0 0 -3.271 1.852 2.12 2.12 0 0 0 -.621 1.5 2.122 2.122 0 0 0 2.121 2.121 2.12 2.12 0 0 0 1.5-.621z" />
      <path d="m14.621 11.621a7.047 7.047 0 0 1 1.852 3.271l.527 2.108-2.108-.527a7.047 7.047 0 0 1 -3.271-1.852 2.12 2.12 0 0 1 -.621-1.5 2.122 2.122 0 0 1 2.121-2.121 2.12 2.12 0 0 1 1.5.621z" />
      <path d="m45.379 25.379a7.047 7.047 0 0 1 -1.852-3.271l-.527-2.108 2.108.527a7.047 7.047 0 0 1 3.271 1.852 2.12 2.12 0 0 1 .621 1.5 2.122 2.122 0 0 1 -2.121 2.121 2.12 2.12 0 0 1 -1.5-.621z" />
      <path d="m49.379 11.621a7.047 7.047 0 0 0 -1.852 3.271l-.527 2.108 2.108-.527a7.047 7.047 0 0 0 3.271-1.852 2.12 2.12 0 0 0 .621-1.5 2.122 2.122 0 0 0 -2.121-2.121 2.12 2.12 0 0 0 -1.5.621z" />
      <path d="m36 30v-9.959l-.041-.041h-7.545a3.414 3.414 0 0 0 -3.414 3.414 21.34 21.34 0 0 1 -1.81 8.586h10.81a2 2 0 0 0 2-2z" />
    </g>
    <path
      d="m59 58v-2h-32.673a3.865 3.865 0 0 1 -3.179-1.791 4.992 4.992 0 0 0 -4.148-2.209 4.915 4.915 0 0 0 -2.158.5 4.176 4.176 0 0 1 -3.684 0 4.915 4.915 0 0 0 -2.158-.5 5 5 0 0 0 -4.182 2.259 3.715 3.715 0 0 1 -3.085 1.741h-.05a1.674 1.674 0 0 0 -1.683 1.672v1.328h56a1 1 0 0 0 1-1z"
      fill="#a0a0a0"
    />
  </svg>
)

export const PinkKimono = () => (
  <svg height="64" viewBox="-8 0 464 464" width="64" xmlns="http://www.w3.org/2000/svg">
    <path
      d="m336 40 24 32 24 72h64l-5.992188 113.832031c-1.332031 25.363281-5.53125 50.496094-12.511718 74.921875l-1.230469 4.324219c-12.484375 43.703125-47.898437 77.0625-92.265625 86.921875h-8v-360zm0 0"
      fill="#f9adb1"
    />
    <path
      d="m112 40-24 32-24 72h-64l5.992188 113.832031c1.332031 25.363281 5.53125 50.496094 12.511718 74.921875l1.230469 4.324219c12.484375 43.703125 47.898437 77.0625 92.265625 86.921875h16v-368zm0 0"
      fill="#f9adb1"
    />
    <path d="m336 464h-224v-424l48-40h136l40 40zm0 0" fill="#ffb1b5" />
    <path d="m112 96h224v176h-224zm0 0" fill="#c7463b" />
    <path
      d="m56 184c-.65625.003906-1.3125-.078125-1.953125-.238281-4.285156-1.074219-6.886719-5.421875-5.816406-9.707031l8-32c.679687-2.789063 2.800781-5.003907 5.5625-5.796876 2.757812-.796874 5.730469-.050781 7.789062 1.953126 2.058594 2 2.886719 4.953124 2.171875 7.734374l-8 32c-.894531 3.554688-4.085937 6.050782-7.753906 6.054688zm0 0"
      fill="#eea5a9"
    />
    <path d="m112 128h224v112h-224zm0 0" fill="#e85244" />
    <path d="m112 176h224v16h-224zm0 0" fill="#b94137" />
    <path
      d="m224 192h-24c-13.253906 0-24-10.746094-24-24s10.746094-24 24-24c17.671875 0 32 14.328125 32 32v8c0 4.417969-3.582031 8-8 8zm-24-32c-4.417969 0-8 3.582031-8 8s3.582031 8 8 8h16c0-8.835938-7.164062-16-16-16zm0 0"
      fill="#b94137"
    />
    <path
      d="m205.792969 222.398438c-3.03125 0-5.800781-1.710938-7.15625-4.421876-1.355469-2.710937-1.0625-5.953124.753906-8.375 6.027344-7.957031 10.648437-16.886718 13.664063-26.402343 1.335937-4.210938 5.835937-6.542969 10.050781-5.207031 4.210937 1.335937 6.542969 5.835937 5.207031 10.046874-3.570312 11.257813-9.03125 21.828126-16.152344 31.25-1.523437 1.972657-3.875 3.121094-6.367187 3.109376zm0 0"
      fill="#b94137"
    />
    <path
      d="m248 192h-24c-4.417969 0-8-3.582031-8-8v-8c0-17.671875 14.328125-32 32-32 13.253906 0 24 10.746094 24 24s-10.746094 24-24 24zm-16-16h16c4.417969 0 8-3.582031 8-8s-3.582031-8-8-8c-8.835938 0-16 7.164062-16 16zm0 0"
      fill="#b94137"
    />
    <path
      d="m242.207031 222.398438c-2.515625 0-4.886719-1.183594-6.398437-3.199219-7.113282-9.40625-12.574219-19.957031-16.144532-31.199219-1.335937-4.210938.996094-8.710938 5.207032-10.046875 4.214844-1.335937 8.710937.992187 10.050781 5.207031 3.011719 9.527344 7.632813 18.46875 13.664063 26.441406 1.816406 2.421876 2.109374 5.664063.753906 8.375-1.355469 2.710938-4.125 4.421876-7.15625 4.421876zm0 0"
      fill="#b94137"
    />
    <g fill="#ff9abc">
      <path d="m280 296h16v16h-16zm0 0" />
      <path d="m280 328h16v16h-16zm0 0" />
      <path d="m296 312h16v16h-16zm0 0" />
      <path d="m264 312h16v16h-16zm0 0" />
      <path d="m376 240h16v16h-16zm0 0" />
      <path d="m376 272h16v16h-16zm0 0" />
      <path d="m392 256h16v16h-16zm0 0" />
      <path d="m360 256h16v16h-16zm0 0" />
      <path d="m176 312h16v16h-16zm0 0" />
      <path d="m176 360h16v16h-16zm0 0" />
      <path d="m200 336h16v16h-16zm0 0" />
      <path d="m152 336h16v16h-16zm0 0" />
      <path d="m72 248h16v16h-16zm0 0" />
      <path d="m72 296h16v16h-16zm0 0" />
      <path d="m48 272h16v16h-16zm0 0" />
    </g>
    <path
      d="m392 184c-3.667969-.003906-6.859375-2.5-7.753906-6.054688l-8-32c-.714844-2.78125.113281-5.734374 2.171875-7.734374 2.058593-2.003907 5.03125-2.75 7.789062-1.953126 2.761719.792969 4.882813 3.007813 5.5625 5.796876l8 32c1.070313 4.285156-1.53125 8.632812-5.816406 9.707031-.640625.160156-1.296875.242187-1.953125.238281zm0 0"
      fill="#eea5a9"
    />
    <path d="m256 392h16v16h-16zm0 0" fill="#ff9abc" />
    <path d="m256 424h16v16h-16zm0 0" fill="#ff9abc" />
    <path d="m272 408h16v16h-16zm0 0" fill="#ff9abc" />
    <path d="m240 408h16v16h-16zm0 0" fill="#ff9abc" />
    <path d="m136 408h16v56h-16zm0 0" fill="#f3a8ac" />
    <path d="m136 376h16v16h-16zm0 0" fill="#f3a8ac" />
    <path d="m184 0 40 72 40-72zm0 0" fill="#eea5a9" />
    <path d="m200 96h40l-40-96h-40zm0 0" fill="#c3c3c3" />
    <path d="m248 96h-40l40-96h40zm0 0" fill="#d1d1d1" />
  </svg>
)

export const BlueKimono = () => (
  <svg height="64" viewBox="-16 0 464 464" width="64" xmlns="http://www.w3.org/2000/svg">
    <path d="m336 464h-240v-424l48-40h152l40 40zm0 0" fill="#3b869e" />
    <path d="m393.632812 212.949219 11.3125-11.3125 1.414063 1.414062-11.3125 11.316407zm0 0" />
    <path d="m96 40-56 48-40 216 96 96zm0 0" fill="#69b0bb" />
    <path d="m336 40 56 48 40 216-96 96zm0 0" fill="#69b0bb" />
    <path
      d="m408.5 177.300781c-17-4.800781-34.601562 5.097657-39.398438 22.097657-4.800781 17 5.097657 34.601562 22.097657 39.402343 9.5 2.699219 19.800781.800781 27.699219-5.101562zm0 0"
      fill="#cbcbcb"
    />
    <path d="m28.683594 255.996094 11.316406-11.3125 11.3125 11.3125-11.3125 11.3125zm0 0" fill="#cbcbcb" />
    <path
      d="m14.601562 225.398438-9.300781 50.101562c10.699219 19.199219 34.898438 26.199219 54.097657 15.5 3.203124-1.800781 6.203124-4.101562 8.800781-6.699219 15.699219-15.5 15.902343-40.699219.402343-56.402343-14.5-14.898438-38.101562-15.898438-54-2.5zm42.398438 47.601562c-9.398438 9.398438-24.601562 9.398438-33.898438 0-9.300781-9.398438-9.402343-24.601562 0-33.898438 9.398438-9.402343 24.597657-9.402343 33.898438 0 9.300781 9.296876 9.300781 24.5 0 33.898438zm0 0"
      fill="#cbcbcb"
    />
    <path d="m168 328h96v136h-96zm0 0" fill="#428dc5" />
    <path d="m176 56 56 144h-56zm0 0" fill="#277baa" />
    <path d="m224 80-32-80h-40l51.199219 128zm0 0" fill="#b9b9b9" />
    <path d="m264 40-64 160h64zm0 0" fill="#428dc5" />
    <path d="m200 200h-40l80-200h40zm0 0" fill="#cdcdcd" />
    <path d="m256 0h32v464h-32zm0 0" fill="#1f6c8c" />
    <path d="m144 0h32v464h-32zm0 0" fill="#1f6c8c" />
    <path d="m96 200h240v128h-240zm0 0" fill="#176177" />
    <path d="m96 257.601562h240v16h-240zm0 0" fill="#ea9d2d" />
    <g fill="#fbb540">
      <path d="m216 273.601562h-24c-13.300781 0-24-10.703124-24-24 0-13.300781 10.699219-24 24-24 17.699219 0 32 14.296876 32 32v8c0 4.398438-3.601562 8-8 8zm-24-32c-4.398438 0-8 3.597657-8 8 0 4.398438 3.601562 8 8 8h16c0-8.800781-7.199219-16-16-16zm0 0" />
      <path d="m197.800781 304c-4.402343 0-8-3.601562-8-8 0-1.699219.597657-3.398438 1.597657-4.800781 6-8 10.601562-16.898438 13.703124-26.398438 1.296876-4.199219 5.796876-6.5 10-5.199219 4.199219 1.296876 6.5 5.796876 5.199219 10-3.601562 11.199219-9 21.796876-16.101562 31.199219-1.5 2-3.898438 3.199219-6.398438 3.199219zm0 0" />
      <path d="m240 273.601562h-24c-4.398438 0-8-3.601562-8-8v-8c0-17.703124 14.300781-32 32-32 13.300781 0 24 10.699219 24 24 0 13.296876-10.699219 24-24 24zm-16-16h16c4.398438 0 8-3.601562 8-8 0-4.402343-3.601562-8-8-8-8.800781 0-16 7.199219-16 16zm0 0" />
      <path d="m234.199219 304c-2.5 0-4.898438-1.199219-6.398438-3.199219-7.101562-9.402343-12.601562-20-16.101562-31.199219-1.300781-4.203124 1-8.703124 5.199219-10 4.203124-1.300781 8.703124 1 10 5.199219 3 9.5 7.601562 18.5 13.703124 26.398438 2.699219 3.5 1.898438 8.5-1.601562 11.199219-1.398438 1-3.101562 1.601562-4.800781 1.601562zm0 0" />
    </g>
    <path d="m176 400 48 64h-48zm0 0" fill="#1f6c8c" />
  </svg>
)
